import React from 'react'
import { Table } from 'reactstrap'

import { NarrowCell } from '../common/Table'
import { Modality } from '../../lib/modalityHelpers'

interface SiteSection {
  title: string
  description: string
}

interface SiteCategory {
  name: string
  modality: Modality
  header: string
  sites: SiteSection[]
}

interface Props {
  modality: Modality
}

export default function SitesOverview(props: Props) {
  const siteData: SiteCategory[] = [
    {
      name: 'CT',
      modality: Modality.Catscan,
      header: '1 site, 2 site, whole body',
      sites: [
        {
          title: 'Head & Neck',
          description: 'Front of Nose to T1',
        },
        {
          title: 'Neck & Thorax',
          description: 'C1 to L3, (cannot include any of the skull or past L3)',
        },
        {
          title: 'Thorax',
          description: 'C6 to L3 (without forelimbs)',
        },
        {
          title: 'Abdomen',
          description: 'T7 to hips (without hindlimbs)',
        },
        {
          title: 'Limb',
          description: 'elbow/stifle + carpus/tarsus + shoulder/hip',
        },
      ],
    },
    {
      name: 'MRI',
      modality: Modality.MRI,
      header: '1 site, 2 site, whole body',
      sites: [
        {
          title: 'Brain',
          description: 'Nose to C2',
        },
        {
          title: 'Cervical',
          description: 'C1 to T3',
        },
        {
          title: 'Thoracolumbar',
          description: 'T3 to L3',
        },
        {
          title: 'Lumbosacral',
          description: 'L4 to S3',
        },
        {
          title: 'Limb',
          description: 'elbow/stifle + carpus/tarsus + shoulder/hip',
        },
      ],
    },
  ].filter((category) => category.modality === props.modality)

  return (
    <div className="d-flex flex-column gap-20px width-550px">
      {siteData.map((category, index) => (
        <div key={index} className="mb-0">
          <p className="mb-1">
            <span className="text-l bold">{category.name}</span>
            <span className="text-m regular ml-2">{category.header}</span>
          </p>

          <Table bordered className="mb-0 text--white">
            <thead>
              <tr>
                <th style={{ lineHeight: 1, width: '33%' }} className="text-s">
                  Site <span className="regular"></span>
                </th>
                <th style={{ lineHeight: 1 }} className="text-s bold">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {category.sites.map((site, siteIndex) => (
                <tr key={siteIndex}>
                  <NarrowCell style={{ lineHeight: 1 }} className="text-s">
                    {site.title}
                  </NarrowCell>
                  <NarrowCell style={{ lineHeight: 1 }} className="text-s">
                    {site.description}
                  </NarrowCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  )
}
