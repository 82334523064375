import CONFIG from '../config'
import { Conditions_conditions } from '../hasura/graphQlQueries/types/Conditions'
import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'

// @ts-ignore
import { get_condition_config, getLikelihood } from '../lib/ml_rules_engine.js'
// @ts-ignore
import aiConfig from '../../aiConfig.json'

export interface RagReport {
  combined_report: string
  components: Record<
    string,
    {
      mild: string
      moderate: string
      severe: string
      normal: string
      recommended: string
      selected: string
    }
  >
}

const rename_condition_config_keys = (condition_config: any) => ({
  likely: condition_config['p_th'],
  possible: condition_config['possible_p_th'],
  potential: condition_config['potential_p_th'],
})

const getRagParams = (consultation: Consultations_consultations, conditions: Conditions_conditions[]) => {
  const { patient } = consultation.case

  const aiPredictions = []
  const allPredictions = []
  const medicalImages = []

  for (const m of consultation.case.medical_images) {
    const predictions = []

    for (const permutation of m.medical_image_permutations) {
      for (const prediction of permutation.ai_predictions_denormalized) {
        try {
          const condition = conditions.find((c) => c.ml_name === prediction.ml_name)
          if (!condition) continue

          const conditionConfig = get_condition_config(aiConfig, prediction, patient.species, true)

          predictions.push({
            condition: condition.display_name,
            probability: prediction.probability.toFixed(10),
            cropped_view: permutation.label,
            thresholds: rename_condition_config_keys(conditionConfig),
          })

          aiPredictions.push({
            condition: condition.display_name,
            probability: prediction.probability,
            prediction: prediction,
            view: m.view?.display_name,
          })
        } catch (e) {
          continue
        }
      }
    }

    medicalImages.push({ view: m.view?.display_name, predictions: predictions })
  }

  aiPredictions.sort((a, b) => a.condition.localeCompare(b.condition) || b.probability - a.probability)
  const uniqueAiPredictions = Array.from(new Map(aiPredictions.map((pred) => [pred.condition, pred])).values())
  for (const prediction of uniqueAiPredictions) {
    try {
      const probability = prediction.probability
      const conditionConfig = get_condition_config(aiConfig, prediction.prediction, patient.species, true)
      const likelihood = getLikelihood(prediction.prediction, prediction.view, patient.species, true, aiConfig)
      allPredictions.push({
        probability: probability.toFixed(10),
        likelihood: likelihood || 'unlikely',
        condition: prediction.condition,
        source: 'AI',
        thresholds: rename_condition_config_keys(conditionConfig),
      })
    } catch (e) {
      continue
    }
  }

  return {
    created_at: consultation.created_at,
    patient_breed: patient.breed,
    patient_species: patient.species,
    patient_weight: patient.weight,
    patient_birthdate: patient.birthdate,
    patient_neutered: patient.neutered,
    patient_gender: patient.gender,
    patient_history: consultation.sending_vet_notes,
    medical_images: medicalImages,
    predictions: allPredictions,
  }
}

export const callRagApi = async (
  consultation: Consultations_consultations,
  conditions: Conditions_conditions[]
): Promise<RagReport | undefined> => {
  try {
    const input = getRagParams(consultation, conditions)
    console.log('FETCHING RAG', CONFIG.RAG_API_ENDPOINT)
    const result = await fetch(CONFIG.RAG_API_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${CONFIG.RAG_ACCESS_TOKEN}` },
      body: JSON.stringify({ input, opening_sentence: false }),
    })
    const data: RagReport = await result.json()
    console.log('RAG RESULT', data)
    // @ts-ignore
    return data
  } catch (e) {
    console.log('RAG ERROR', e)
  }
}
