import { MutableRefObject } from 'react'
import { ohifStudyUrl } from '../lib/helpers'
import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'

export const fadeViewerWindowIn = (viewerWindowRef: React.MutableRefObject<Window | null>) => {
  let origin
  if (window.location.origin === 'http://localhost:8000') {
    origin = 'http://localhost:3000'
  } else if (window.location.origin === 'https://radimal-vet-staging.onrender.com') {
    origin = 'https://viewer.stage-1.radimal.ai'
  } else if (window.location.origin === 'https://vet.radimal.ai') {
    origin = 'https://view.radimal.ai'
  }

  viewerWindowRef.current?.postMessage({ type: 'FADE', value: false }, origin ? origin : '*')
}

export const fadeViewerWindowOut = (viewerWindowRef: React.MutableRefObject<Window | null>) => {
  let origin
  if (window.location.origin === 'http://localhost:8000') {
    origin = 'http://localhost:3000'
  } else if (window.location.origin === 'https://radimal-vet-staging.onrender.com') {
    origin = 'https://viewer.stage-1.radimal.ai'
  } else if (window.location.origin === 'https://vet.radimal.ai') {
    origin = 'https://view.radimal.ai'
  }
  viewerWindowRef.current?.postMessage({ type: 'FADE', value: true }, origin ? origin : '*')
}

export const getViewerWindowData = () => {
  const handleMessage = (event: any) => {
    const allowedOrigins = ['http://localhost:3000', 'https://viewer.stage-1.radimal.ai', 'https://view.radimal.ai']

    if (!allowedOrigins.includes(event.origin)) return

    const windowData = localStorage.getItem('windowData')

    let windows = []
    if (windowData) {
      try {
        windows = JSON.parse(windowData)
      } catch (error) {
        console.error('Error parsing windowData:', error)
        windows = []
        windows.push(event.data)
        localStorage.setItem('windowData', JSON.stringify(event.data))
      }
    }

    if (!Array.isArray(windows)) {
      windows = []
    }
    const index = windows.findIndex((w: any) => w.id === event.data.id)
    if (index === -1) {
      localStorage.setItem('windowData', JSON.stringify(event.data))
    } else {
      windows[index] = event.data
      localStorage.setItem('windowData', JSON.stringify(windows))
    }
  }

  window.addEventListener('message', handleMessage)

  return () => {
    window.removeEventListener('message', handleMessage)
  }
}

export const fadeOnNavigate = (viewerWindowRef: MutableRefObject<Window | null>) => {
  const sendFadeState = () => {
    if (window.location.pathname !== '/consultations') {
      fadeViewerWindowOut(viewerWindowRef)
    }
  }

  const originalPushState = history.pushState
  const originalReplaceState = history.replaceState

  history.pushState = function (...args) {
    originalPushState.apply(this, args)
    sendFadeState()
  }

  history.replaceState = function (...args) {
    originalReplaceState.apply(this, args)
    sendFadeState()
  }

  window.addEventListener('popstate', sendFadeState)

  return () => {
    window.removeEventListener('popstate', sendFadeState)
    history.pushState = originalPushState
    history.replaceState = originalReplaceState
  }
}

export const openViewer = (
  consultation: Consultations_consultations | undefined,
  viewerWindow: Window | null | undefined,
  setViewerWindow: (window: Window | null) => void,
  viewerWindowRef: MutableRefObject<Window | null>,
  setUsingViewer: (usingViewer: boolean) => void
) => {
  if (!consultation) return
  const viewerUrl = ohifStudyUrl(consultation.case.dicom_server_study_instance_uid, consultation.case.dicom_source)

  if (!viewerWindow || viewerWindow.closed) {
    const windowData = localStorage.getItem('windowData')
    let lastWindow
    if (windowData) {
      const windowPosition = JSON.parse(windowData)
      lastWindow = `left=${windowPosition.x},top=${windowPosition.y},width=${windowPosition.width},height=${windowPosition.height}`
    }
    const newWindow = window.open(viewerUrl, 'viewerWindow', lastWindow || undefined)
    setViewerWindow(newWindow)
    setUsingViewer(true)
    viewerWindowRef.current = newWindow
    localStorage.setItem('usingViewer', 'true')
  } else if (viewerWindow && !viewerWindow.closed) {
    let origin
    if (window.location.origin === 'http://localhost:8000') {
      origin = 'http://localhost:3000'
    } else if (window.location.origin === 'https://radimal-vet-staging.onrender.com') {
      origin = 'https://viewer.stage-1.radimal.ai'
    } else if (window.location.origin === 'https://vet.radimal.ai') {
      origin = 'https://view.radimal.ai'
    }
    // const existingWindow = window.open('', 'viewerWindow')
    viewerWindow?.postMessage({ type: 'CLOSE', value: true }, origin ? origin : '*')
    localStorage.setItem('usingViewer', 'false')
  }
  return viewerWindow
}
