import React, { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import extend from 'lodash/extend'
import { useSelector } from 'react-redux'

import SeverityGradient, { SeverityThreshold } from './SeverityGradient'
import { RagReport } from '../../services/rag'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'

// @ts-ignore
import deleteIcon from '../../lib/images/subtracting-button.svg'

interface Props {
  report: RagReport
  setNotes: (notes: string) => void
  notes?: string
}

export default (props: Props) => {
  const { report } = props

  const { conditions }: ConsultationsState = useSelector(consultationsSelector)
  const [editedRagReport, setEditedRagReport] = useState<RagReport | null>(null)

  useEffect(() => {
    const editedComponents: any = {}
    Object.keys(report.components).forEach((component) => {
      editedComponents[component] = extend({}, report.components[component], {
        selected: capitalize(report.components[component].recommended),
        recommended: report.components[component].recommended,
      })
    })
    setEditedRagReport({ combined_report: report.combined_report, components: editedComponents })
  }, [report])

  if (!editedRagReport) {
    return null
  }

  const updateSeverity = (mlName: string, severity?: SeverityThreshold) => {
    if (!severity || !props.notes) return

    // @ts-ignore
    const edited = cloneDeep(editedRagReport)
    edited.components[mlName].selected = severity
    setEditedRagReport(edited)

    const component = editedRagReport.components[mlName]
    // @ts-ignore
    const currentText = component[component.selected.toLowerCase()] || ''
    // @ts-ignore
    const updatedText = component[severity.toLowerCase()] || ''
    props.setNotes(props.notes.replace(currentText, updatedText))
  }

  return (
    <div className="text-dark-bg mb-4">
      <p className="text-m mb-1">✨ Success!</p>

      <div className="d-flex flex-wrap gap-20px">
        {Object.keys(editedRagReport.components).map((mlName, idx) => {
          const condition = conditions.find((condition) => mlName.replace(/_/g, ' ') === condition.ml_name || '')
          const severity = SeverityThreshold[editedRagReport.components[mlName].selected as keyof typeof SeverityThreshold]

          if (!condition || severity === SeverityThreshold.Absent) {
            return null
          }

          return (
            <div key={idx}>
              <div className="d-flex align-items-center mb-1">
                <p className="mb-0 text-m bold">{condition.display_name}</p>
                <img
                  onClick={() => updateSeverity(mlName, SeverityThreshold.Absent)}
                  className="icon-xxxs ml-2 pointer"
                  src={deleteIcon}
                />
              </div>

              <SeverityGradient threshold={severity} updateSeverity={(severity) => updateSeverity(mlName, severity)} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
