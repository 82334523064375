import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { isNumber } from 'lodash'
import { useSelector } from 'react-redux'

import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { Textarea } from '../common/Input'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { inCase } from '../../lib/helpers'

interface Props {
  consultation: Consultations_consultations
  setRegionOfInterest: (regionOfInterest?: RegionOfInterestParams) => void
  regionOfInterest?: RegionOfInterestParams
  setDisplayRegionOfInterest: (displayRegionOfInterest: boolean) => void
}

interface Coordinate {
  x: number
  y: number
}

interface Arrow {
  start: Coordinate
  end: Coordinate
}

export interface RegionOfInterestParams {
  arrows: Arrow[]
  imageId: number
  text: string
}

export default function RegionOfInterestCompon(props: Props) {
  const { presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const [selected, setSelected] = useState<string | undefined>(undefined)
  const [text, setText] = useState<string | undefined>()
  const [arrows, setArrows] = useState<Arrow[]>([])
  const [startArrow, setStartArrow] = useState<Coordinate | undefined>(undefined)

  /*
    Effects
  */

  useEffect(() => {
    if (!props.regionOfInterest) return

    const { imageId, arrows, text } = props.regionOfInterest
    const image = props.consultation.case.medical_images.find((m) => m.id === imageId)
    const selected = image?.aws_s3_url && presignedCaseImageUrls.find((p) => p.includes(image.aws_s3_url || ''))
    if (!selected) return

    setSelected(selected)
    setArrows(arrows)
    setText(text)
  }, [props.regionOfInterest])

  /*
    Methods
  */

  const handleImageClick = (e: any) => {
    const xCoordinate = e.nativeEvent.offsetX
    const yCoordinate = e.nativeEvent.offsetY
    const elem = document.getElementById('selected-image')!
    if (!elem) return

    const x = Math.round((xCoordinate / elem.offsetWidth) * 100) / 100
    const y = Math.round((yCoordinate / elem.offsetHeight) * 100) / 100

    if (startArrow) {
      setArrows(arrows.concat({ start: startArrow, end: { x, y } }))
      setStartArrow(undefined)
    } else {
      setStartArrow({ x, y })
    }
  }

  const coordinateComponent = (coordinate: Coordinate, idx?: number) => (
    <div
      onClick={() => {
        if (isNumber(idx) && window.confirm('Delete Arrow?')) {
          setArrows(arrows.filter((_, idx2) => idx2 !== idx))
        }
      }}
      style={{ left: `${coordinate.x * 100}%`, top: `${coordinate.y * 100}%`, marginLeft: '-6px', marginTop: '-6px' }}
      className="position-absolute pointer"
    >
      <div className="arrow" />

      <p className="text--primary text-xs">START</p>
    </div>
  )

  const handleDiscard = () => {
    props.setRegionOfInterest(undefined)
    props.setDisplayRegionOfInterest(false)
  }

  const handleSave = () => {
    const image = props.consultation.case.medical_images.find((i) => i.aws_s3_url && selected?.includes(i.aws_s3_url))
    if (!text || !image) return

    props.setRegionOfInterest({ text, arrows, imageId: image.id })
    props.setDisplayRegionOfInterest(false)
  }

  return (
    <div className="w-100 pb-5" style={{ flex: 2 }}>
      <div className="d-flex">
        <h6 style={{ width: '150px' }} className="text-dark-bg">
          Select image
        </h6>

        {presignedCaseImageUrls?.length > 0 && (
          <div role="button" className="py-2 d-flex flex-wrap">
            {inCase(presignedCaseImageUrls, props.consultation.case).map((src: string, idx: number) => (
              <img
                onClick={() => {
                  setArrows([])
                  setSelected(src)
                }}
                key={idx}
                className={`mr-1 mb-1 thumbnail ${src === selected ? 'border border--primary border-width-2' : ''}`}
                src={src}
              />
            ))}
          </div>
        )}
      </div>

      <div className="d-flex mt-4">
        <h6 style={{ width: '150px' }} className="text-dark-bg">
          Draw arrows
        </h6>

        <div>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            {selected && (
              <img
                id="selected-image"
                onClick={handleImageClick}
                style={{ maxWidth: '400px', maxHeight: '400px', width: 'auto', height: 'auto', cursor: 'crosshair' }}
                src={selected}
              />
            )}

            {arrows.map((arrow, idx) => (
              <svg className="position-absolute left-0 top-0 w-100 h-100 pe-none" key={idx}>
                <g className="pe-auto">
                  <line
                    x1={`${arrow.start.x * 100}%`}
                    y1={`${arrow.start.y * 100}%`}
                    x2={`${arrow.end.x * 100}%`}
                    y2={`${arrow.end.y * 100}%`}
                    stroke="#fe01a1"
                    strokeWidth="2"
                    markerEnd="url(#arrowhead)"
                    className="pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      setArrows(arrows.filter((_, i) => i !== idx))
                    }}
                  />
                </g>
                <defs>
                  <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="6" refY="3.5" orient="auto">
                    <polygon points="0 0, 7 3.5, 0 7" fill="#fe01a1" />
                  </marker>
                </defs>
              </svg>
            ))}

            {startArrow && coordinateComponent(startArrow)}
          </div>
        </div>
      </div>

      <div className="d-flex mt-4">
        <h6 style={{ width: '150px' }} className="text-dark-bg">
          Add text
        </h6>

        <Textarea
          className="data-hj-allow"
          dark
          onChange={(e) => setText(e.target.value)}
          placeholder="Notes"
          autoFocus
          style={{ width: '100%', height: `80px`, maxWidth: '400px' }}
          value={text || ''}
        />
      </div>

      <div style={{ width: '100px' }} className="d-flex justify-content-start align-items-start flex-column">
        <Button disabled={!arrows.length || !text} onClick={handleSave} className="w-100 mt-4 mb-2" color="dark-bg-blue">
          Save
        </Button>

        <Button onClick={handleDiscard} outline className="w-100 mr-3" color="danger">
          Discard
        </Button>
      </div>
    </div>
  )
}
