import React from 'react'

import SitesTooltip from '../common/SitesTooltip'
import { CtOrMriSite, CTSites, Modality, MRISites } from '../../lib/modalityHelpers'

// @ts-ignore
import ballotBox from '../../lib/images/ballot-box.svg'
// @ts-ignore
import ballotBoxChecked from '../../lib/images/ballot-box-checked.svg'

interface Props {
  modality: Modality
  setSites: (sites: CtOrMriSite[]) => void
  sites?: CtOrMriSite[]
}

export default function SitesSelect(props: Props) {
  const options = props.modality === Modality.Catscan ? CTSites : MRISites

  return (
    <div className="d-flex align-items-center" role="group">
      <div className="d-flex align-items-center gap-10px min-width-100px">
        <SitesTooltip shouldDisplayText={false} modality={props.modality} />

        <h6 className="text-dark-bg m-0">Sites</h6>
      </div>

      {options.map((option) => {
        const isSelected = props.sites?.includes(option)

        return (
          <label
            onClick={() =>
              // @ts-ignore
              props.setSites(isSelected ? props.sites?.filter((s) => s !== option) : [...props.sites, option])
            }
            className="m-0 d-flex align-items-center"
            key={option}
            role="button"
          >
            <img className="mr-2 icon-xxxs" src={isSelected ? ballotBoxChecked : ballotBox} />

            <p className="text-dark-bg text-s m-0">
              <b className="text-capitalize pr-3">{option}</b>
            </p>
          </label>
        )
      })}
    </div>
  )
}
