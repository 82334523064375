import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import SitesOverview from '../request-consult/sitesOverview'
import { Modality } from '../../lib/modalityHelpers'

// @ts-ignore
import questionMark from '../../lib/images/question-blue.svg'

interface Props {
  modality: Modality
  shouldDisplayText: boolean
}

export default function SitesTooltip(props: Props) {
  return (
    <div className="d-inline-block position-relative">
      <div className="d-flex gap-5px align-items-center" id="sites-overview-tooltip">
        {props.shouldDisplayText && <p className="text-xs text--newblue font-italic m-0">Learn about how we count sites.</p>}
        <img src={questionMark} alt="Help" className="icon-xxs" />
      </div>

      <UncontrolledTooltip
        arrowClassName="tooltip-arrow-light-horizontal"
        innerClassName="tooltip-inner-light max-width-700px"
        target="sites-overview-tooltip"
        trigger="hover"
        placement="right"
      >
        <SitesOverview modality={props.modality} />
      </UncontrolledTooltip>
    </div>
  )
}
