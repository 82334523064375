import React from 'react'
import { Button } from 'reactstrap'

import ConsensusStatement from './ConsensusStatement'
import Followups from './Followups'
import { Textarea } from '../../components/common/Input'
import { RegionOfInterestParams } from './RegionOfInterest'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'

// @ts-ignore
import target from '../../lib/images/target.svg'
// @ts-ignore
import followup from '../../lib/images/follow-up.svg'
// @ts-ignore
import whisper from '../../lib/images/whisper.svg'

interface Props {
  consultation: Consultations_consultations
  displayFollowup: boolean
  displayPrivateNotes: boolean
  followupDays?: number
  followupText?: string
  inQueue: boolean
  isConsensus: boolean
  privateNotes?: string
  regionOfInterest?: RegionOfInterestParams
  setDisplayFollowup: (displayFollowup: boolean) => void
  setDisplayPrivateNotes: (displayPrivateNotes: boolean) => void
  setDisplayRegionOfInterest: (displayRegionOfInterest: boolean) => void
  setFollowupDays: (followupDays: number) => void
  setFollowupText: (followupText: string) => void
  setIsConsensus: (isConsensus: boolean) => void
  setPrivateNotes: (privateNotes: string) => void
}

export default function AdditionalActions(props: Props) {
  const {
    consultation,
    displayFollowup,
    displayPrivateNotes,
    followupDays,
    followupText,
    inQueue,
    isConsensus,
    privateNotes,
    regionOfInterest,
    setDisplayFollowup,
    setDisplayPrivateNotes,
    setFollowupDays,
    setFollowupText,
    setIsConsensus,
    setPrivateNotes,
  } = props

  return (
    <div className="flex-grow">
      {inQueue && <ConsensusStatement isConsensus={isConsensus} setIsConsensus={setIsConsensus} />}

      <div className="d-flex gap-15px text-dark-bg mb-1">
        <Button
          size="sm"
          onClick={() => setDisplayPrivateNotes(!displayPrivateNotes)}
          className="text-dark-bg bg--black border--text flex-center single-line"
        >
          <img src={whisper} className="icon-xs mr-1" />
          {displayPrivateNotes ? 'Close' : 'Add'} private notes
        </Button>

        <Button
          size="sm"
          onClick={() => setDisplayFollowup(!displayFollowup)}
          className="text-dark-bg bg--black border--text flex-center single-line"
        >
          <img src={followup} className="icon-xs mr-1" />
          {displayFollowup ? 'Close' : 'Request'} followup
        </Button>

        {inQueue && (
          <Button
            size="sm"
            onClick={() => props.setDisplayRegionOfInterest(true)}
            className="text-dark-bg bg--black border--text flex-center single-line"
          >
            <img src={target} className="icon-xs mr-1" />
            {regionOfInterest ? 'Edit' : 'Draw'} region of interest
          </Button>
        )}
      </div>

      {displayPrivateNotes && (
        <Textarea
          className="mt-2 mb-1 data-hj-allow"
          dark
          disabled={!inQueue}
          onChange={(e) => setPrivateNotes(e.target.value)}
          placeholder="Private Notes"
          autoFocus
          style={{ width: '100%', height: `100px`, maxWidth: '900px' }}
          value={privateNotes || ''}
        />
      )}

      {displayFollowup && (
        <Followups
          consultation={consultation}
          followupDays={followupDays}
          setFollowupDays={setFollowupDays}
          followupText={followupText}
          setFollowupText={setFollowupText}
          inQueue={inQueue}
        />
      )}
    </div>
  )
}
