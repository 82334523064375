import React from 'react'

export enum SeverityThreshold {
  Absent = 'Absent',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
  Normal = 'Normal',
}

export const SEVERITIES = [SeverityThreshold.Normal, SeverityThreshold.Mild, SeverityThreshold.Moderate, SeverityThreshold.Severe]

interface Props {
  threshold: SeverityThreshold
  updateSeverity: (severity: SeverityThreshold) => void
}

const marginForThreshold = (threshold: SeverityThreshold) => {
  if (threshold === SeverityThreshold.Severe) {
    return 6
  } else if (threshold === SeverityThreshold.Moderate) {
    return 62
  } else if (threshold === SeverityThreshold.Mild) {
    return 118
  } else if (threshold === SeverityThreshold.Normal) {
    return 174
  }
}

export default (props: Props) => {
  const margin = marginForThreshold(props.threshold)

  const isNormal = props.threshold === SeverityThreshold.Normal

  return (
    <div
      style={{ padding: '2px' }}
      className={`width-200px ${isNormal ? 'healthy' : props.threshold.toLowerCase()}-bg position-relative rounded pointer`}
    >
      <div style={{ marginRight: `${margin}px` }} className="position-relative transition-s">
        {!isNormal && <p className="m-0 semibold text-m text-white text-right mr-4">{props.threshold}</p>}

        <div
          className="position-absolute right-0"
          style={{
            bottom: '-2px',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '16px solid white',
          }}
        />

        {isNormal && <p className="m-0 semibold text-m text-white text-right ml-4">{props.threshold}</p>}
      </div>

      {SEVERITIES.map((severity) => {
        if (severity === props.threshold) return null

        return (
          <div
            onClick={() => props.updateSeverity(severity)}
            style={{ marginRight: `${marginForThreshold(severity)}px` }}
            className="position-relative"
          >
            <div
              className="position-absolute right-0 hover-severity-gradient-triangle transition-f"
              style={{
                bottom: '-2px',
                width: 0,
                height: 0,
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderBottom: '13px solid white',
                opacity: 0.25,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
