import React, { useEffect } from 'react'
import compact from 'lodash/compact'
import orderBy from 'lodash/orderBy'
import { useDispatch, useSelector } from 'react-redux'

import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { consultationsSelector, ConsultationsState, fetchImagesAction } from '../../hasura/slices/consultations'
import { getHeatmapImages } from '../../lib/aiHelpers'

interface Props {
  condition: Conditions_conditions
  consultation: Consultations_consultations
}

export default function Gradcams(props: Props) {
  const dispatch = useDispatch()

  const { presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const heatmapImages = orderBy(getHeatmapImages(props.consultation.case), ['probability'], ['desc'])
  const mlName = props.condition.ml_name?.replace(' ', '_') || ''
  const heatmapImagesKeys = compact(heatmapImages.map((a) => a.heatmap)).filter((key) => key.includes(mlName))

  useEffect(() => {
    dispatch(fetchImagesAction(heatmapImagesKeys))
  }, [])

  const images = compact(heatmapImagesKeys.map((key) => presignedCaseImageUrls.find((p) => p.includes(key)))).slice(0, 3)

  if (!mlName || !images.length) {
    return null
  }

  return (
    <div className="position-absolute t-0 l-0 mt-2 border border--text rounded p-3 bg--black z-max-2 d-flex gap-10px">
      {images.map((image, idx) => (
        <img className="height-250px w-auto" key={idx} src={image} alt="heatmap" />
      ))}
    </div>
  )
}
