import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { Badge, Tooltip, CustomInput, Alert, Spinner } from 'reactstrap'
import { useIntercom } from 'react-use-intercom'
import { useDispatch, useSelector } from 'react-redux'

import Addendums from './Addendums'
import AdditionalActions from './AdditionalActions'
import CONFIG from '../../config'
import ConditionBadge from '../common/ConditionBadge'
import Gradcams from './Gradcams'
import RagReportComponents from './RagReportComponents'
import RegionOfInterest, { RegionOfInterestParams } from './RegionOfInterest'
import SitesSelect from './SitesSelect'
import formatGroupLabel from './GroupLabel'
import { AiMode } from '../../interfaces/AiMode'
import { CONSENSUS_TEXT } from './ConsensusStatement'
import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { Input, Textarea } from '../../components/common/Input'
import { OrganizationEvent } from '../../lib/organizationEventTypes'
import { QueryName } from '../../hasura/queryNames'
import { callRagApi, RagReport } from '../../services/rag'
import { consultationUsesExtraRegionPricing, modalityForConsultation, CtOrMriSite } from '../../lib/modalityHelpers'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { getGpPredictions, gpFilteredPredictions } from '../../lib/aiHelpers'
import { insertOrganizationEventForUserAction } from '../../hasura/slices/organizations'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  allIndexesOf,
  commaSeparatedString,
  conditionOptions,
  filterConditions,
  stringSimilarity,
  uniqueCategoriesFor,
  vetFirstName,
} from '../../lib/helpers'

interface Props {
  addendum?: string
  addendumPrivateText?: string
  checked: string[]
  consultation: Consultations_consultations
  customFinding: string
  customFindings: predictions_normalized_insert_input[]
  displayRegionOfInterest: boolean
  followupDays?: number
  followupText?: string
  handleAddCustomFinding: (displayName: string, conditionCategoryId: number) => void
  handleRemoveCustomFinding: (finding: predictions_normalized_insert_input) => void
  handleUpdateChecked: (category: string) => void
  inQueue: boolean
  isLocked: boolean
  lock: (_?: any) => void
  notes: string | undefined
  privateNotes: string | undefined
  regionOfInterest?: RegionOfInterestParams
  selectedCondition: (condition: Conditions_conditions) => void
  ragReport?: RagReport
  selectedConditions: predictions_normalized_insert_input[]
  setAddendum: (addendum: string) => void
  setAddendumPrivateText: (addendumPrivateText: string) => void
  setCustomFinding: (customFinding: string) => void
  setDisplayRegionOfInterest: (displayRegionOfInterest: boolean) => void
  setFollowupDays: (followupDays: number) => void
  setFollowupText: (followupText: string) => void
  setNotes: (notes: string) => void
  setPrivateNotes: (privateNotes: string) => void
  setRegionOfInterest: (regionOfInterest?: RegionOfInterestParams) => void
  setSitesReviewed: (sitesReviewed: CtOrMriSite[]) => void
  sitesReviewed?: CtOrMriSite[]
  unrecognizedSpeech?: string
}

const BOND_ENTERPRISE_ID = 133

const RAG_USER_IDS = [
  'google-oauth2|103581764472610970570', // meg
  'auth0|65dd1a1a83dd1bf756ab06e8', // joe
  'google-oauth2|109072470665309799621', // oliver
  'google-oauth2|109065940804516119057', // alan
]

export default (props: Props) => {
  const { trackEvent } = useIntercom()
  const dispatch = useDispatch()

  const {
    addendum,
    addendumPrivateText,
    checked,
    consultation,
    customFinding,
    customFindings,
    handleAddCustomFinding,
    handleRemoveCustomFinding,
    handleUpdateChecked,
    inQueue,
    isLocked,
    notes,
    privateNotes,
    selectedCondition,
    selectedConditions,
    setAddendum,
    setAddendumPrivateText,
    setCustomFinding,
    setNotes,
    setPrivateNotes,
    followupText,
    setFollowupText,
    followupDays,
    setFollowupDays,
  } = props

  const { conditions, isQuerying }: ConsultationsState = useSelector(consultationsSelector)
  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const [openTooltipId, setOpenTooltipId] = useState<string | undefined>(undefined)
  const [displayPrivateNotes, setDisplayPrivateNotes] = useState(false)
  const [displayFollowup, setDisplayFollowup] = useState(false)
  const [justSelected, setJustSelected] = useState<string | undefined>()
  const [autocompleteClosed, setAutocompleteClosed] = useState(false)
  const [isConsensus, setIsConsensus] = useState(false)
  const [hoverConditionId, setHoverConditionId] = useState<number | undefined>()
  const [ragReport, setRagReport] = useState<RagReport | undefined>()
  const [isFetchingRag, setIsFetchingRag] = useState(false)

  const toggleTooltip = (e: any) => setOpenTooltipId(openTooltipId === e.srcElement.id ? undefined : e.srcElement.id)

  const filteredConditions = filterConditions(conditions, consultation.case.patient.species)
  const options = conditionOptions(filteredConditions, selectedConditions)

  const regions = compact(uniq(filteredConditions.map((c) => c.category?.region))).sort()
  const useExtraRegionPricing = consultationUsesExtraRegionPricing(consultation)

  const ragEnabled =
    flatten(consultation.case.medical_images.map((m) => m.medical_image_permutations.map((p) => p.label))).some((l) =>
      ['spine', 'forearm', 'hips'].includes(l)
    ) &&
    user &&
    (RAG_USER_IDS.includes(user.id) || CONFIG.IS_DEVELOPMENT)

  /*
    Effects
  */

  useEffect(() => {
    if (!customFinding) setAutocompleteClosed(false)
  }, [customFinding])

  useEffect(() => {
    setTimeout(() => setJustSelected(undefined), 750)
  }, [justSelected])

  useEffect(() => {
    setDisplayPrivateNotes(consultation.receiving_vet_private_notes !== null)
    setDisplayFollowup(false)
    setRagReport(undefined)
  }, [consultation])

  useEffect(() => {
    if (!notes?.includes(CONSENSUS_TEXT) && isConsensus) {
      setNotes((notes ? notes : '') + CONSENSUS_TEXT)
    } else {
      setNotes(notes?.replace(CONSENSUS_TEXT, '') || '')
    }
  }, [isConsensus])

  /*
    Methods
  */

  const categories = uniqueCategoriesFor(filteredConditions)

  const noIndexes = allIndexesOf(' no ', notes || '')

  const suggestions = orderBy(
    filteredConditions.filter((c) => {
      const isSelected = selectedConditions.some((s) => s.condition_id === c.id)
      const index = (notes || '').toLowerCase().indexOf(` ${c.display_name.toLowerCase()}`)
      const isNegation = noIndexes.some((idx) => Math.abs(index - idx) < 25)
      const isAiPredication = gpFilteredPredictions(consultation.case, conditions, AiMode.Default).includes(c.display_name)
      return !isSelected && ((index > -1 && !isNegation) || isAiPredication)
    }),
    ['display_name'],
    ['asc']
  )

  if (props.displayRegionOfInterest) {
    return (
      <RegionOfInterest
        regionOfInterest={props.regionOfInterest}
        setRegionOfInterest={props.setRegionOfInterest}
        setDisplayRegionOfInterest={props.setDisplayRegionOfInterest}
        consultation={consultation}
      />
    )
  }

  const fetchRagData = async () => {
    setIsFetchingRag(true)
    dispatch(
      insertOrganizationEventForUserAction(accessToken, OrganizationEvent.ClickedSuggestMusculoskeletalNotes, user?.organization.id, {
        consultation_id: consultation.id,
      })
    )
    const data = await callRagApi(consultation, conditions)
    setIsFetchingRag(false)
    if (!data) return

    setRagReport(data)
    const ragNotes = `MUSCULOSKELETAL\n\n${data.combined_report}`
    props.setNotes(props.notes ? `${props.notes}\n\n${ragNotes}` : ragNotes)
  }

  const selectCondition = (condition: Conditions_conditions) => {
    selectedCondition(condition)
    setJustSelected(condition.display_name)
  }

  const fuzzyMatch = sortBy(
    filteredConditions.map((condition) => ({
      condition,
      stringSimilarity: Math.max(
        stringSimilarity(customFinding, condition.display_name),
        stringSimilarity(customFinding, condition?.ml_name || '')
      ),
    })),
    (f: any) => -f.stringSimilarity
  )
    .filter((f) => f.stringSimilarity >= 0.15)
    .slice(0, 3)

  const showAutocomplete = fuzzyMatch.length > 0 && !autocompleteClosed

  const isBondConsultation = consultation.case.dicom_server?.organization?.enterprise.id === BOND_ENTERPRISE_ID

  const signatureIncludedUnnecessarily = ['dvm', 'dacvr', vetFirstName(user?.display_name).toLowerCase()].some((s) =>
    [(notes || '').slice(-100), (addendum || '').slice(-100), followupText || ''].join(' ').toLowerCase().includes(s)
  )

  return (
    <form
      onClick={() => {
        if (!isLocked) props.lock()
      }}
      className="w-100 pb-5"
    >
      {inQueue && (
        <div className="d-flex align-items-start">
          <h6 className="text-dark-bg min-width-100px">Conditions</h6>

          <div>
            <div className="d-flex gap-20px">
              <Select
                className="react-select-dark-container"
                classNamePrefix="react-select-dark"
                controlShouldRenderValue={false}
                placeholder="Search..."
                options={options}
                // @ts-ignore
                formatGroupLabel={formatGroupLabel}
                onChange={(option: any) => {
                  const selected = filteredConditions.find((c) => c.id === option.value)
                  if (!selected) return

                  selectCondition(selected)
                }}
              />

              <div className="position-relative">
                <Input
                  dark
                  className="data-hj-allow"
                  style={{ width: '200px' }}
                  autoComplete="off"
                  onChange={(e) => setCustomFinding(e.target.value)}
                  placeholder="Custom..."
                  type="text"
                  value={customFinding || ''}
                />

                {customFinding && (
                  <div style={{ height: 0, bottom: '-10px', width: '250px', zIndex: 99999 }} className="position-absolute left-0">
                    {showAutocomplete ? (
                      <div className="bg--dark p-2 rounded">
                        <p className="text-xxs ls-sm bold m-0">AUTOCOMPLETE</p>

                        <div>
                          {fuzzyMatch.map((f) => (
                            <p
                              onClick={() => {
                                setCustomFinding('')
                                selectCondition(f.condition)
                              }}
                              className="pointer text-s m-0 hover-underline mt-1"
                            >
                              {f.condition.display_name}
                            </p>
                          ))}

                          <p onClick={() => setAutocompleteClosed(true)} className="pointer text-s m-0 hover-underline mt-1">
                            None of these
                          </p>
                        </div>
                      </div>
                    ) : (
                      categories.map((category, idx) => (
                        <Badge
                          className="mx-1"
                          key={idx}
                          color="dark-bg-blue"
                          onClick={() => {
                            setJustSelected(customFinding)
                            // @ts-ignore
                            handleAddCustomFinding(customFinding, category.id)
                          }}
                          role="button"
                        >
                          {category.display_name}
                        </Badge>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>

            {inQueue && (
              <div className="d-flex align-items-start mt-3 position-relative min-height-50px">
                {suggestions.length > 0 && (
                  <p style={{ right: '100%' }} className="text-dark-bg position-absolute text-xs mr-2">
                    Suggestions:
                  </p>
                )}

                <div className="d-flex flex-wrap gap-10px">
                  {suggestions.map((condition, idx) => {
                    const isGpPredication = getGpPredictions(consultation.case)
                      .map((p) => p.display_name || p.condition?.display_name)
                      .includes(condition.display_name)
                    const displayGradcam = !isGpPredication && condition.id === hoverConditionId
                    return (
                      <div
                        className="position-relative"
                        onMouseOver={() => setHoverConditionId(condition.id)}
                        onMouseLeave={() => setHoverConditionId(undefined)}
                      >
                        <ConditionBadge
                          glow={condition.display_name === justSelected}
                          key={idx}
                          name={!isGpPredication ? condition.display_name : `${condition.display_name} (Clinician)`}
                          category={condition.category?.display_name}
                          onClick={() => {
                            setHoverConditionId(undefined)
                            trackEvent('clicked-suggestion')
                            setJustSelected(condition.display_name)
                            selectedCondition(condition)
                          }}
                        />
                        {displayGradcam && <Gradcams condition={condition} consultation={consultation} />}
                      </div>
                    )
                  })}

                  {ragEnabled && !ragReport && (
                    <div className={`d-flex align-items-center ${isFetchingRag ? 'pe-none' : ''}`}>
                      <ConditionBadge name="✨ Suggest musculoskeletal notes" onClick={fetchRagData} />
                      {isFetchingRag && <Spinner size="sm" color="primary" className="ml-1" />}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {inQueue && ragReport && <RagReportComponents setNotes={props.setNotes} report={ragReport} notes={notes} />}

      {useExtraRegionPricing ? (
        <div className="my-3">
          <SitesSelect
            sites={props.sitesReviewed || []}
            setSites={props.setSitesReviewed}
            modality={modalityForConsultation(consultation)}
          />
          {props.sitesReviewed && props.sitesReviewed.length > (consultation.sites_requested || '').split(',').length && (
            <Alert color="secondary" className="text-s mt-1 mb-0 width-fit-content">
              The clinic requested {commaSeparatedString(consultation.sites_requested?.split(',') || [])}, but you reviewed{' '}
              {props.sitesReviewed.length} sites. Your earnings will be adjusted accordingly.
              {(consultation.stat_type?.hours || 9) <= 1
                ? " Additionally, there's no need to complete the consult within an hour."
                : ''}
            </Alert>
          )}
        </div>
      ) : (
        <div className="my-3 d-flex align-items-center" role="group">
          <h6 className="text-dark-bg min-width-100px">Checked</h6>

          {regions.map((region) => {
            const tooltipId = `${region}-tooltip`

            return (
              <label className="d-flex align-items-center" key={region} role="button">
                <CustomInput
                  checked={checked.includes(region)}
                  className="m-0 data-hj-allow"
                  color="secondary"
                  disabled={!inQueue}
                  id={region}
                  onChange={() => handleUpdateChecked(region)}
                  role="button"
                  type="switch"
                  value={region}
                />

                <p className="text-dark-bg text-s m-0">
                  <b id={tooltipId} className="text-capitalize pr-3">
                    {region}
                  </b>

                  <Tooltip fade={false} placement="top" isOpen={openTooltipId === tooltipId} target={tooltipId} toggle={toggleTooltip}>
                    <div className="text-left text-xs">
                      {uniq(filteredConditions.filter((c) => c.category?.region === region).map((c) => c.category?.display_name))
                        .sort()
                        .join(', ')}
                    </div>
                  </Tooltip>
                </p>
              </label>
            )
          })}
        </div>
      )}

      {isBondConsultation && (
        <Alert color="secondary" className="text-s mw-100 width-fit-content">
          Bond Vet appreciates all possible, even minor, and incidental findings, with listed interpretations and recommendations for
          each
        </Alert>
      )}

      <Textarea
        className="mb-1 min-height-400px max-height-800px data-hj-allow max-width-900px w-100"
        dark
        disabled={!inQueue}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Notes"
        style={{ height: '50vh' }}
        value={notes || ''}
      />

      <div className="d-flex align-items-start justify-content-between max-width-900px">
        {inQueue && (
          <AdditionalActions
            consultation={consultation}
            displayFollowup={displayFollowup}
            displayPrivateNotes={displayPrivateNotes}
            followupDays={followupDays}
            followupText={followupText}
            inQueue={inQueue}
            isConsensus={isConsensus}
            privateNotes={privateNotes}
            regionOfInterest={props.regionOfInterest}
            setDisplayFollowup={setDisplayFollowup}
            setDisplayPrivateNotes={setDisplayPrivateNotes}
            setDisplayRegionOfInterest={props.setDisplayRegionOfInterest}
            setFollowupDays={setFollowupDays}
            setFollowupText={setFollowupText}
            setIsConsensus={setIsConsensus}
            setPrivateNotes={setPrivateNotes}
          />
        )}

        <div className={`pl-5 flex-center transition-m ${isQuerying[QueryName.UpdateConsultationNotes] ? '' : 'opacity-0'}`}>
          <p className="text-dark-bg text-m mb-0 mr-1">Saving</p>
          <Spinner size="sm" color="primary" />
        </div>
      </div>
      {!inQueue && (
        <Addendums
          consultation={consultation}
          addendum={addendum}
          setAddendum={setAddendum}
          addendumPrivateText={addendumPrivateText}
          setAddendumPrivateText={setAddendumPrivateText}
        />
      )}
      {signatureIncludedUnnecessarily && (
        <Alert color="secondary" className="text-s width-fit-content mt-2">
          Looks like you might have added your name/signature - Radimal automatically adds it for you!
        </Alert>
      )}
      <div style={{ minHeight: '32px' }} className={`d-flex mt-4 gap-10px flex-wrap pe-${inQueue ? 'auto' : 'none'}`}>
        {filteredConditions
          .filter((c) => selectedConditions.map((s) => s.condition_id).includes(c.id))
          .map((condition: Conditions_conditions, idx: number) => (
            <ConditionBadge
              glow={condition.display_name === justSelected}
              key={idx}
              name={condition.display_name}
              category={condition.category?.display_name}
              onClick={() => selectedCondition(condition)}
            />
          ))}

        {customFindings.map((finding, idx) => (
          <ConditionBadge
            glow={finding.display_name === justSelected}
            key={idx}
            name={finding.display_name!}
            category={categories.find((c) => c.id === finding.condition_category_id)?.display_name}
            onClick={() => handleRemoveCustomFinding(finding)}
          />
        ))}
      </div>
    </form>
  )
}
